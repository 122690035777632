<template>
  <v-container>
    <v-btn @click="addFormToShow" color="primary"><v-icon left>mdi-plus</v-icon>Ajouter un tag</v-btn>
    <v-data-table
      dense
      :headers="headers"
      :items="tags"
      item-key="code"
      :search="search"
      :custom-filter="filterCode"
      @click:row="selectedRow"
    >
      <template v-slot:top>
        <v-text-field
          append-icon="mdi-magnify"
          v-model="search"
          label="Filtrer">
        </v-text-field>
      </template>
    </v-data-table>
    <v-card v-if="selectedTag !== null">
      <v-card-text>
        <v-container>
          <v-text-field v-model="selectedTag.id" label="ID" :disabled="true"/>
          <v-text-field v-model="selectedTag.code" label="Code"/>
          <v-btn @click="saveTag" color="indigo"><v-icon left>mdi-content-save</v-icon>Sauvegarder le tag</v-btn>
          <v-btn @click="deleteTag" color="error"><v-icon left>mdi-delete</v-icon>Supprimer</v-btn>
        </v-container>
        <v-container v-if="selectedTagTranslations !== null">
          <div v-for="tagTranslation in selectedTagTranslations" :key="`${'translation' + tagTranslation.id}`">
            <v-text-field v-model="tagTranslation.id" label="ID" :disabled="true"/>
            <v-text-field v-model="tagTranslation.language" label="Langue"/>
            <v-text-field v-model="tagTranslation.title" label="Titre"/>
            <v-btn @click="saveTranslation(tagTranslation)" color="indigo"><v-icon left>mdi-content-save</v-icon>Sauvegarder la traduction</v-btn>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card v-if="showAddForm">
      <v-card-text>
        <v-container>
          <h4>Ajouter un tag</h4>
          <v-text-field v-model="tagToAdd.code" label="Code"/>
          <v-text-field v-model="tagToAdd.fr" label="Français"/>
          <v-text-field v-model="tagToAdd.en" label="Anglais"/>
          <v-btn @click="addTag" color="indigo"><v-icon>mdi-content-save</v-icon>Sauvegarder</v-btn>
        </v-container>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
    >
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import DataRequest from '../../libs/DataRequest';

export default {
  name: 'Tags',
  data: () => {
    return {
      search: '',
      headers: [
        {
          text: 'Identifiant',
          value: 'id'
        },
        {
          text: 'Code',
          value: 'code'
        }
      ],
      tags: [],
      selectedTag: null,
      selectedTagTranslations: null,
      showAddForm: false,
      tagToAdd: null,
      snackbar: false,
      snackbarMsg: ''
    }
  },
  mounted() {
    this.updateTags();
    this.resetTagToAdd();
  },
  methods: {
    updateTags() {
      DataRequest.get('/tag/', (result) => {
        this.tags = result;
      });
    },
    filterCode(value, search, item) {
      return value !== null && search !== null && search !== '' && item.code.indexOf(search.toLocaleLowerCase()) !== -1;
    },
    selectedRow(selectedTag) {
      this.showAddForm = false;
      this.selectedTag = selectedTag;
      this.selectedTagTranslations = null;
      DataRequest.get('/tag/' + selectedTag.id + '/translations', (result) => {
        this.selectedTagTranslations = result;
      });
    },
    saveTag() {
      DataRequest.put('/tag/' + this.selectedTag.id, this.selectedTag, () => {
        this.updateTags();
        this.selectedTag = null;
      });
    },
    saveTranslation(translation) {
      DataRequest.put('/tagstring/' + translation.id, translation, () => {
        this.updateTags();
      });
    },
    resetTagToAdd() {
      this.tagToAdd = {
        code: '',
        fr: '',
        en: ''
      }
    },
    addTag() {
      DataRequest.post('/tag/', {code: this.tagToAdd.code}, (tagResult) => {
        this.snackbarMsg = 'Sauvegarde du tag : OK';
        this.snackbar = true;
        this.showAddForm = false;
        DataRequest.post('/tagstring/', {language: 'fr', title: this.tagToAdd.fr, tag: tagResult.id}, (frResult) => {
          this.snackbarMsg += '<br/>Sauvegarde du title français (' + frResult.id + '): OK';
        });
        DataRequest.post('/tagstring/', {language: 'en', title: this.tagToAdd.en, tag: tagResult.id}, (enResult) => {
          this.snackbarMsg += '<br/>Sauvegarde du title anglais (' + enResult.id + '): OK';
          this.updateTags();
        });
      });
    },
    deleteTag() {
      DataRequest.delete('/tag/' + this.selectedTag.id, () => {
        this.selectedTag = null;
        this.updateTags();
      });
    },
    addFormToShow() {
      this.showAddForm = true;
      this.selectedTag = null;
      this.resetTagToAdd();
    }
  }
}
</script>

<style scoped>

</style>