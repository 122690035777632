<template>
  <v-container>
    <v-btn @click="addFormToShow" color="primary"><v-icon left>mdi-plus</v-icon>Ajouter un place</v-btn>
    <v-data-table
      dense
      :headers="headers"
      :items="places"
      item-key="title"
      :search="search"
      :custom-filter="filterTitle"
      @click:row="selectedRow"
    >
      <template v-slot:top>
        <v-text-field
          append-icon="mdi-magnify"
          v-model="search"
          label="Filtrer">
        </v-text-field>
      </template>
    </v-data-table>
    <v-card v-if="selectedPlace !== null">
      <v-card-text>
        <v-container>
          <v-text-field v-model="selectedPlace.id" label="ID" :disabled="true"/>
          <v-text-field v-model="selectedPlace.latitude" label="Latitude"/>
          <v-text-field v-model="selectedPlace.longitude" label="Longitude"/>
          <TagsSelection v-model="selectedPlace.tags" />
          <v-btn @click="savePlace" color="indigo"><v-icon left>mdi-content-save</v-icon>Sauvegarder le place</v-btn>
          <v-btn @click="deletePlace" color="error"><v-icon left>mdi-delete</v-icon>Supprimer</v-btn>
        </v-container>
        <v-container v-if="selectedPlaceTranslations !== null">
          <div v-for="placeTranslation in selectedPlaceTranslations" :key="`${'translation' + placeTranslation.id}`">
            <v-text-field v-model="placeTranslation.id" label="ID" :disabled="true"/>
            <v-text-field v-model="placeTranslation.language" label="Langue"/>
            <v-text-field v-model="placeTranslation.title" label="Titre"/>
            <v-textarea v-model="placeTranslation.description" label="Description"/>
            <v-btn @click="saveTranslation(placeTranslation)" color="indigo"><v-icon left>mdi-content-save</v-icon>Sauvegarder la traduction</v-btn>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card v-if="showAddForm">
      <v-card-text>
        <v-container>
          <h4>Ajouter un place</h4>
          <v-text-field v-model="placeToAdd.latitude" label="Latitude"/>
          <v-text-field v-model="placeToAdd.longitude" label="Longitude"/>
          <TagsSelection v-model="placeToAdd.tags" />
          <v-text-field v-model="placeToAdd.titleFr" label="Titre français"/>
          <v-textarea v-model="placeToAdd.descriptionFr" label="Description française"/>
          <v-text-field v-model="placeToAdd.titleEn" label="Titre anglais"/>
          <v-textarea v-model="placeToAdd.descriptionEn" label="Description anglais"/>
          <v-btn @click="addPlace" color="indigo"><v-icon>mdi-content-save</v-icon>Sauvegarder</v-btn>
        </v-container>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :timeout="4000"
    >
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import DataRequest from '../../libs/DataRequest';
import TagsSelection from "@/components/Admin/TagsSelection";

export default {
  name: 'Places',
  components: {TagsSelection},
  data: () => {
    return {
      search: '',
      headers: [
        {
          text: 'Identifiant',
          value: 'id'
        },
        {
          text: 'Titre',
          value: 'title'
        }
      ],
      places: [],
      selectedPlace: null,
      selectedPlaceTranslations: null,
      showAddForm: false,
      placeToAdd: null,
      snackbar: false,
      snackbarMsg: ''
    }
  },
  mounted() {
    this.updatePlaces();
    this.resetPlaceToAdd();
  },
  methods: {
    updatePlaces() {
      DataRequest.get('/place/with-title', (result) => {
        this.places = result;
      });
    },
    filterTitle(value, search, item) {
      return value !== null && search !== null && search !== '' && item.title.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
    },
    selectedRow(rowPlaceData) {
      this.showAddForm = false;
      if (rowPlaceData !== null) {
        DataRequest.get('/place/' + rowPlaceData.id, (result) => {
          this.selectedPlace = result;
          this.selectedPlaceTranslations = null;
          DataRequest.get('/place/' + rowPlaceData.id + '/placestrings', (result) => {
            for (const placeTranslation of result) {
              placeTranslation.description = placeTranslation.description.replace(/<br\s*[/]?>/gi, "\n");
            }
            this.selectedPlaceTranslations = result;
          });
        });
      }
    },
    savePlace() {
      DataRequest.put('/place/' + this.selectedPlace.id, this.selectedPlace, () => {
        this.updatePlaces();
        this.selectedPlace = null;
      });
    },
    saveTranslation(translation) {
      const translationCopy = JSON.parse(JSON.stringify(translation));
      translationCopy.description = translationCopy.description.replace(/\n/gi, '<br/>');
      DataRequest.put('/placestring/' + translation.id, translationCopy);
    },
    resetPlaceToAdd() {
      this.placeToAdd = {
        latitude: 0.0,
        longitude: 0.0,
        tags: [],
        titleFr: '',
        descriptionFr: '',
        titleEn: '',
        descriptionEn: ''
      }
    },
    addPlace() {
      DataRequest.post('/place/', {
        latitude: this.placeToAdd.latitude,
        longitude: this.placeToAdd.longitude,
        tags: this.placeToAdd.tags
      }, (placeResult) => {
        this.snackbarMsg = 'Sauvegarde du lieu.';
        this.snackbar = true;
        this.showAddForm = false;
        DataRequest.post('/placestring/', {
              language: 'fr',
              title: this.placeToAdd.titleFr,
              description: this.placeToAdd.descriptionFr,
              place: placeResult.id
        }, (frResult) => {
          this.updatePlaces();
          this.snackbarMsg += ' Sauvegarde du title français (' + frResult.id + ').';
        });
        DataRequest.post('/placestring/', {
          language: 'en',
          title: this.placeToAdd.titleEn,
          description: this.placeToAdd.descriptionEn,
          place: placeResult.id
        }, (enResult) => {
          this.snackbarMsg += ' Sauvegarde du title anglais (' + enResult.id + ').';
        });
      });
    },
    deletePlace() {
      DataRequest.delete('/place/' + this.selectedPlace.id, () => {
        this.selectedPlace = null;
        this.updatePlaces();
      });
    },
    addFormToShow() {
      this.showAddForm = true;
      this.selectedPlace = null;
      this.resetPlaceToAdd();
    }
  },
}
</script>

<style scoped>

</style>