<template>
  <v-container>
  <v-expansion-panels popout>
    <v-expansion-panel v-for="userRequest in userRequests" v-bind:key="userRequest.id">
      <v-expansion-panel-header>
        <v-row>
          <v-col>
            {{ userRequest.subject }}
          </v-col>
          <v-col>
            {{ new Date(userRequest.sendDate['date']).toLocaleString() }}
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col>
            {{ userRequest.name }}
          </v-col>
          <v-col>
            {{ userRequest.email }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>{{ userRequest.content }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="showDeleteConfirmDialog(userRequest.id)">{{ $t('Delete') }}</v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  <ConfirmDialog
      :title="$t('Confirm')"
      :content="$t('Are you sure you want to delete this item?')"
      v-model="deleteConfirmDialogShowed"
      v-on:confirm="deleteUserRequest"></ConfirmDialog>
  </v-container>
</template>

<script>
import ConfirmDialog from '../../components/ConfirmDialog.vue';
import DataRequest from '../../libs/DataRequest';

export default {
  name: 'UserRequests',
  components: {
    ConfirmDialog
  },
  data: () => ({
    userRequests: [],
    deleteConfirmDialogShowed: false,
    selectedUserRequestId: -1
  }),
  created() {
    this.updateUserRequests();
  },
  methods: {
    showDeleteConfirmDialog(userRequestId) {
      this.selectedUserRequestId = userRequestId;
      this.deleteConfirmDialogShowed = true;
    },
    deleteUserRequest() {
      if (this.selectedUserRequestId !== -1) {
        DataRequest.delete(`/userrequest/${this.selectedUserRequestId}`, (result) => {
          if (this.selectedUserRequestId === parseInt(result, 10)) {
            this.selectedUserRequestId = -1;
            this.updateUserRequests();
          }
        });
      }
    },
    updateUserRequests() {
      DataRequest.get('/userrequest/', (result) => {
        this.userRequests = result;
      });
    },
  },
};
</script>

<style scoped>

</style>
