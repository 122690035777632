<template>
  <v-container>
    <v-main>
      <v-tabs v-model="tab">
        <v-tab>UserRequests</v-tab>
        <v-tab>Tags</v-tab>
        <v-tab>Places</v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="tab">
        <v-tab-item><UserRequests></UserRequests></v-tab-item>
        <v-tab-item><Tags></Tags></v-tab-item>
        <v-tab-item><Places></Places></v-tab-item>
      </v-tabs-items>
    </v-main>
  </v-container>
</template>

<script>
import DataRequest from '../libs/DataRequest';
import Places from '../components/Admin/Places.vue';
import Tags from '../components/Admin/Tags.vue';
import UserRequests from '../components/Admin/UserRequests.vue';

export default {
  name: 'Admin',
  components: {
    Places,
    Tags,
    UserRequests
  },
  data: () => ({
    tab: null
  }),
  created() {
    // Redirection si l'utilisateur n'est pas connecté
    if (!DataRequest.isConnected() && !DataRequest.reconnect()) {
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>

</style>
