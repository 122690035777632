<template>
  <v-dialog
    v-model="showed">
    <v-card>
      <v-toolbar
          color="primary"
          dark
      >{{ title }}</v-toolbar>
      <v-card-text>
        {{ content }}
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
            text
            @click="showed = false"
        >{{ $t('Close') }}</v-btn>
        <v-btn
            text
            @click="confirm()"
        >{{ $t('Confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    value: Boolean,
    title: String,
    content: String,
  },
  computed: {
    showed: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    confirm() {
      this.showed = false;
      this.$emit('confirm');
    },
  },
};
</script>

<style scoped>

</style>
