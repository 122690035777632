<template>
  <v-container>
  <v-row align="center" justify="start">
    <v-col v-for="(tag, tagIndex) in selectedTagsObj" :key="tag.id" class="shrink">
      <v-chip close @click:close="value.splice(tagIndex, 1)">
        {{ tag.code }}
      </v-chip>
    </v-col>
    <v-col cols="12">
      <v-text-field
          v-model="tagSearch"
          full-width
          hide-details
          label="Recherche"
          single-line
      ></v-text-field>
    </v-col>
  </v-row>
  <v-virtual-scroll :items="filteredTags" :item-height="50" height="300">
    <template v-slot:default="{ item }" >
      <v-list-item :key="item.id" @click="addTag(item.id)">
        <v-list-item-title v-text="item.code"></v-list-item-title>
      </v-list-item>
    </template>
  </v-virtual-scroll>
  </v-container>
</template>

<script>
import DataRequest from "@/libs/DataRequest";

export default {
  name: 'TagsSelection',
  props: {
    value: Array
  },
  data: () => {
    return {
      tags: [],
      tagSearch: '',
    }
  },
  mounted() {
    this.updateTags();
  },
  methods: {
    updateTags() {
      DataRequest.get('/tag/', (result) => {
        this.tags = result;
      });
    },
    addTag(tagId) {
      this.value.push(parseInt(tagId));
    }
  },
  computed: {
    selectedTags: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    },
    filteredTags() {
      return this.tags.filter((tag) => {
        return tag.code.toLocaleLowerCase().indexOf(this.tagSearch.toLocaleLowerCase()) !== -1 && !this.value.includes(parseInt(tag.id));
      });
    },
    selectedTagsObj() {
      return this.tags.filter((tag) => {
        return this.value.includes(parseInt(tag.id));
      });
    }
  }
}
</script>

<style scoped>

</style>